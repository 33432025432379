.api-wrapper {
  border-radius: 4px;
  border: 1px solid #EAEDF0;
}

.divider-wraper {
  height: 0px;
  align-self: stretch;

  .divider {
    stroke-width: 1px;
    stroke: #80869C;
  }
}

.api-section {
  padding: 16px;

  p {
    margin: 0;
  }

  .api-section-heading {
    font-size: 16px;
    color: $gray-800;
    font-weight: $font-weight-semibold;
    margin-bottom: 16px;
    }
  .pipe-divider {
    margin: 0 16px;
  }
  img {
    max-width: 100%;
  }
}

.product-type-btn {
  display: flex;
  width: 240px;
  height: 72px;
  padding: 24px 16px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #EAEDF0;
  background: #F1F5F7;
  align-items: center;

  span:first-of-type {
    height: 20px;
  }
  
  span:last-of-type {
    color: #323A46;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px; /* 125% */
  }
}

.prodyct-type-row {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}