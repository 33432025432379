.producer-states-row {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  
  @media (min-width: 991px) { 
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1rem), 1fr));
  }

  @media (min-width: 1280px) { 
    grid-template-columns: repeat(auto-fill, minmax(calc(33.333% - 1rem), 1fr));
  }

  @media (min-width: 1600px) { 
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 1rem), 1fr));
  }
}

.agent-information-row {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (min-width: 991px) { 
    // flex-wrap: wrap;
    flex-direction: row;
  }
  .agent-information-col {
    ul {
      li {
        margin-bottom: 18px;
        color: #343a40;
        font-weight: 600;
        line-height: 20px;
        strong {
          color: #80869C;
        }
      }
    }
  }
}

.agent-rep-page-title {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  @media (min-width: 991px) {
    padding-bottom: 0;
    justify-content: space-between; 
    flex-direction: row;
    align-items: center;
  }
}