.state-checkbox {
  padding: 12px 16px;
  border-radius: 4px;
  background: #FFF;
  border: 1px solid #EAEDF0;
  display: flex;
  gap: 1rem;
  align-items: center;

  span:first-of-type {
    width: 36px;
    text-align: center;
  }

  span:last-of-type {
    width: 24px;
    text-align: center;
  }

  .state-date-col { 
    width: 100%;
  }
}

