// 
// tables.scss
//

.table {
    th {
        font-weight: $font-weight-medium;
    }
}
//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}

.table-container {
    // @media(max-width: map-get($grid-breakpoints, md) - 1) {
    overflow: auto;
    padding: 0 10px 10px;
    margin: 0px -10px;
    // }
}

.custom-table {
    $radius: $border-radius;
    $padding-x: 8px;
    $padding-y: 17px;

    width:100%;
    border-collapse: separate;
    border-spacing: 0;
    thead {
        th {
            background: #FBFBFD;
            border:1px solid #dbdbdc;
            border-style: solid none solid none;
            padding: $padding-y $padding-x;
            font-weight: normal;
            color:#595469;
            &:first-child {
                border-left-style: solid;
                border-top-left-radius: $radius;
            }
            &:last-child {
                border-right-style: solid;
                border-top-right-radius: $radius;
            }
        }
    }
    td, th {
        padding: $padding-y $padding-x;
        font-size: 15px;
        &:first-child {
            padding-left: 26px;
        }
        &:last-child {
            padding-right: 26px;
        }
    }
    tbody{
        tr {
            background: white;
            &:hover {
                td {
                    box-shadow: 0 5px 4px rgba(#1E1157,0.04);
                    &:first-child {
                        &:before {
                            border-left-style: solid;
                        }
                    }
                    &:last-child {
                        &:before {
                            border-right-style: solid;
                        }
                    }
                    &:before {
                        content:'';
                        position: absolute;
                        top:-1px;
                        left:50%;
                        transform: translateX(-50%);
                        width:calc(100% + 14px);
                        height:calc(100% + 2px);
                        background: $gray-100;
                        border:1px solid #dbdbdc;
                        border-style: solid none solid none;
                        border-radius: $radius;
                    }
                }
            }
            &:nth-child(even) {
                background-color: $striped-table-color;
            }
        }
        td {
            border:1px solid #ededed;
            border-style: none none solid none;
            position: relative;
            &:first-child {
                border-left-style: solid;
            }
            &:last-child {
                border-right-style: solid;
            }
        }
    }
}