body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dtSBody {
  position: relative; overflow: auto; width: 100%;
  border: 0px;
}

.dtTWidth {
  width: 1559px;
}

.react-bootstrap-table table {
  table-layout: unset !important;
}

#ScrollToTop {
  position: fixed;
  bottom: 5%;
  right: 2% ;
  z-index: 99;
  border: none;
  outline: none;
  color: white;
  background: #3bafda;
  border-radius: 5px;
  font-size: 25px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-popper { 
  z-index: 10 !important; 
}